@import '~antd/dist/antd.css';
@import '~ant-design-draggable-modal/dist/index.css';

.ant-form-item-explain,
.ant-form-item-extra {
	min-height: 0;
}

.licenses .ant-form-vertical .ant-form-item {
	margin-bottom: 16px;
}
.licenses .ant-form-vertical .ant-form-item-label {
	padding-bottom: 2px;
}
.licenses .ant-form-item-label > label {
	font-size: 12px;
	color: #a0aec0;
}

.ant-menu-submenu > .ant-menu {
	border-radius: 4px !important;
	padding: 8px;
}

/* TODO need to revisit this since it is targeting all the tables */
.ant-table-body {
	max-height: calc(100vh - 465px);
}

.ant-table-row {
	cursor: pointer;
}

.ant-input[disabled] {
	color: rgba(0, 0, 0, 0.35);
}

/*
  rc-menu will put menus outside of the scope of the <App />.
  These styles allow the menu to look correct with theming.
  They also have to be very specific to override the attributes
  used on the generated elements.
*/
.ant-menu-submenu.ant-menu-light > .ant-menu-vertical span,
.ant-menu-submenu.ant-menu-light > .ant-menu-vertical svg,
.ant-menu-submenu.ant-menu-light > .ant-menu-vertical .ant-menu-submenu-title span,
.ant-menu-submenu.ant-menu-light > .ant-menu-vertical .ant-menu-submenu-title svg {
	color: rgba(0, 0, 0, 0.65);
}

/* TODO remove and check this once Antd is upgraded since they fixed this in newer versions */
.ant-picker .ant-picker-suffix {
	display: flex;
	flex: none;
}

/* A fix for select items with longer text overflowing parents, to enable proper text wrapping and item alignment */
.ant-tree-select-dropdown
	.ant-select-tree-list-holder-inner
	.ant-select-tree-treenode
	.ant-select-tree-node-content-wrapper {
	flex: 1;
	word-break: break-all;
}

.ant-table-filter-trigger {
	font-size: 16px;
}

.ant-table-column-sorter-up,
.ant-table-column-sorter-down {
	font-size: 13px;
}

.ant-steps-item-active .ant-steps-item-title {
	font-weight: 500;
}
.ant-steps-item-icon > .ant-steps-icon {
	top: -1px;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	font-weight: 500;
	text-shadow: unset;
}
.ant-tabs-tab .ant-tabs-tab-btn {
	transition-property: color;
}

.ant-select-tree-list-scrollbar.ant-select-tree-list-scrollbar-show {
	display: initial !important;
}

.ant-table-header > table {
	height: 100%;
}

.ant-table-header > table .ant-table-cell {
	padding: 0 8px 0 16px;
}

.ant-table-header > table th > div > .ant-table-column-title:first-of-type {
	padding: 16px 0;
}

.ant-table-header > table .ant-table-filter-column {
	height: 100%;
	align-items: center;
}

.ant-table-header > table .ant-table-filter-column .ant-table-filter-trigger {
	height: 100%;
	margin: 0;
	padding: 18px 8px;
}

.access-group-time-popup .ant-picker-panel {
	min-width: 150px;
}

.ant-btn-primary {
	background-color: #00a0d0;
	border-color: #00a0d0;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
	background-color: #33b3d9;
	border-color: #66c6e3;
}

.ant-input-show-count-suffix {
	font-size: 12px;
	translate: 0 4px;
}

/* TODO ANTD v5: Remove .ant-input-textarea-show-count::after on v5 upgrade */
.ant-input-textarea-show-count::after,
.ant-input-data-count {
	font-size: 12px;
}
